<template>
  <div>
    <template v-if="!this.$store.getters.getTeams().length">
      <div class="row">
        <div class="col justify-content-center">
          <div class="info-component text-center align-middle mt-auto mb-auto">
            <div class="info-component-space">
              <i class="fad fa-empty-set info-component-icon"></i>
            </div>
            <div class="row" style="margin-top: 20px;">
              <div class="col-lg-12">
                <h3>{{ $t('teams.list.empty.title') }}</h3>
                <h5 class="text-muted">{{ $t('teams.list.empty.message') }}</h5>
              </div>
            </div>
            <div v-if="isSubscriber" class="row mt-1">
              <div class="col">
                <button class="btn btn-primary btn-lg text-uppercase" :disabled="submitted ? true : false" v-on:click="createTeam()">
                  <h5 class="mb-0">
                    <half-circle-spinner
                        v-if="submitted"
                        :animation-duration="900"
                        :size="18"
                        class="align-middle d-inline-block"
                    />
                    {{ $t('teams.list.new.button') }}
                  </h5>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
    <template v-else>
      <div v-if="isSubscriber" class="row mb-3">
        <div class="col">
          <button class="btn btn-primary btn-lg text-uppercase float-right" :disabled="submitted" v-on:click="createTeam()">
            <h5 class="mb-0">
              <half-circle-spinner
                  v-if="submitted"
                  :animation-duration="900"
                  :size="18"
                  class="align-middle d-inline-block"
              />
              {{ $t('teams.list.new.button') }}
            </h5>
          </button>
        </div>
      </div>
      <div class="row ml-lg-5 mr-lg-5">
        <div class="col-lg-3 col-sm-12 h-100" v-for="team in this.$store.getters.getTeams()" :key="team.id">
          <div class="card text-white h-100">
            <router-link :to="{name: 'team', params: {team_id: team.id}}">
              <div class="card-body h-100">
                <div class="pull-left position-absolute">
                  <div class="badge badge-primary" v-if="team.is_owner">
                    <h6 class="mb-0">
                      <i class="fad fa-crown"></i>
                      {{ $t('teams.list.owner') }}
                    </h6>
                  </div>
                </div>
                <div class="d-flex flex-row-reverse" style="height:42px">
                  <b-dropdown v-if="!team.is_owner" size="sm" id="leaveTeamDropdown" variant="button" toggle-class="text-decoration-none" lazy no-caret>
                    <template #button-content>
                      <BIconThreeDots style="width: 30px; height: 30px; margin-top: -25px"></BIconThreeDots>
                    </template>
                    <b-dropdown-item class="dropdown-item">
                      <div @click="leaveTeam(team)" class="text-danger">
                        <i class="fad fa-sign-out font-size-16 align-middle mr-1 text-danger"/>
                        {{ $t('teams.list.leave.button') }}
                      </div>

                    </b-dropdown-item>
                  </b-dropdown>
                </div>
                <div class="row justify-content-center">
                  <div class="col text-center">
                    <h3 class="pb-0 mt-4 mb-3 text-truncate">
                      {{ team.identifier }}
                    </h3>
                  </div>
                </div>
              </div>
              <div class="card-footer">
                <div class="row text-center">
                  <div class="col">
                    <p class="text-muted text-truncate mb-2">
                      {{ $t('teams.list.members') }}
                    </p>
                    <h5 class="mb-0">
                      {{ $n(team.memberships) }}
                    </h5>
                  </div>
                </div>
              </div>
            </router-link>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>
<script>
import { HalfCircleSpinner } from 'epic-spinners';
import { with_acrf_token } from "@/methods";
import { BIconThreeDots } from 'bootstrap-vue';

export default {
  components: {
    HalfCircleSpinner,
    BIconThreeDots
  },
  props: {},
  computed: {
    isSubscriber() {
      return this.$store.getters.getSubscriberStatus();
    }
  },
  methods: {
    async leaveTeam(team) {
      let result = await this.$swal({
        icon: 'warning',
        text: this.$t('teams.list.leave.confirm.description'),
        title: this.$t('teams.list.leave.confirm.title'),
        showCancelButton: true,
        confirmButtonText: this.$t('teams.list.leave.confirm.prompt')
      });
      if (!result.isConfirmed) {
        return;
      }

      try {
        const response = await fetch(
            process.env.VUE_APP_ROOT_API + `v1/teams/${team.id}/leave-team`,
            { credentials: 'include' }
        )
        if (!response.ok) {
          throw new Error(`(${this.$vnode.componentOptions.tag}) Failed with API error ${response.status}=${response.statusText} (${response.url})`);
        }
        this.$store.commit('removeTeam', team)
        await this.$swal({
          icon: 'success',
          title: this.$t('teams.list.leave.success')
        });
      } catch (error) {
        console.error(error)
      }
    },
    createTeam() {
      this.submitted = true;
      with_acrf_token().then((r) => {
        let payload = {
          acsrf_token: r.acsrf_token
        };
        fetch(process.env.VUE_APP_ROOT_API + 'v1/teams/list', {
          method: 'POST',
          body: JSON.stringify(payload),
          credentials: 'include'
        })
            .then(response => {
              if (response.ok || response.status === 400) {
                return response.json();
              } else {
                if (response.status === 429) {
                  this.submitted = false;
                  this.$swal({
                    icon: 'warning',
                    text: this.$t('error.server.ratelimit.message'),
                    title: this.$t('error.server.ratelimit.title')
                  });
                } else {
                  throw new Error(`(${this.$vnode.componentOptions.tag}) Failed with API error ${response.status}=${response.statusText} (${response.url})`);
                }
              }
            })
            .then(data => {
              if (!data) {
                return;
              }
              this.submitted = false;
              if (data.status) {
                this.$store.commit('addTeam', {
                  id: data.team_id,
                  identifier: data.team_id,
                  is_owner: true,
                  memberships: 1
                })
                this.$swal({
                  icon: 'success',
                  text: this.$t('teams.list.new.success')
                });
              } else {
                if (data.error === 'limit-reached') {
                  this.$swal({
                    icon: 'error',
                    text: this.$t('teams.list.new.error.limit')
                  });
                } else if (data.error === 'restricted') {
                  this.$swal({
                    icon: 'error',
                    text: this.$t('teams.list.new.error.restricted')
                  });
                } else if (data.error === 'no-subscription') {
                  this.$swal({
                    icon: 'error',
                    text: this.$t('teams.list.new.error.limit')
                  });
                } else {
                  this.$swal({
                    icon: 'error',
                    text: this.$t('teams.list.new.error.generic')
                  });
                }
              }
            })
            .catch(error => {
              this.submitted = false;
              console.log(`[ERROR] ${error}`);
              this.$swal({
                icon: 'error',
                text: this.$t('teams.list.new.error.generic')
              });
            });
      }).catch(error => {
        this.submitted = false;
        console.log('[ERROR] Failed to request acrf token');
        this.$swal({
          icon: 'error',
          text: this.$t('error.server.generic.message')
        });
      });
    }
  },
  created() {
    this.$store.dispatch('fetchTeams')
  },
  data() {
    return {
      ready: false,
      error: false,
      teams: [],
      submitted: false
    }
  }
};
</script>
